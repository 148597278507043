// i18next-extract-mark-ns-start retail-POS-system

import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {OrderedList} from 'components/List';
import 'keen-slider/keen-slider.min.css';
import {Section, SectionActions, SectionHeader} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

import styled from 'styled-components';
import {Flex} from 'components/Flex';
import React from 'react';
import {DownloadOnAppleStore, IosDownloadLink} from 'components/DownloadOnAppleStore';
import {AndroidDownloadLink, DownloadOnGooglePlay} from 'components/DownloadOnGooglePlay';
import Bold from 'components/Bold';
import {AnchorLink} from 'components/AnchorLink';
import {MoneiVideoSection} from 'components/landings/MoneiVideoSection';
import {CompatibleWith} from 'components/monei-pay/CompatibleWith';
import IndexBackground from 'components/monei-pay/IndexBackground';
import {BlogLink} from 'components/links/Blog';
import {InternalPageLink, MoneiPayQrLink, PricingLink} from 'components/links/Pages';
import CardsContainer from 'components/CardsContainer';
import {Card} from 'components/Card';
import cards from 'images/credit-cards.svg';
import mobile_payment from 'images/mobile_payment.svg';
import money from 'images/money.svg';
import cart2 from 'images/cart2.svg';
import megaphone from 'images/megaphone.svg';
import person from 'images/person.svg';
import {SignUpLink} from 'components/links/Other';
import {StyledDiv} from 'components/StyledSx';
import {Background} from 'components/Background';
import {QrGroup} from 'components/monei-pay/QrGroup';
import {RotatedScreenshots} from 'components/monei-pay/RotatedScreenshots';
import {MainTitleContent} from 'components/monei-pay/MainTitleContent';
import {SupportLink} from 'components/links/Support';
import {mapCategory, getPostsFromPrismicEdges} from 'utils/blog-utils';
import {BlogCategoryType, BlogPost} from '../../../types/BlogTypes';
import {RelatedBlogPosts} from 'components/RelatedBlogPosts';

const CardWrapper = styled.div`
  max-width: 230px;
`;

const CardImage = styled.img`
  position: absolute;
`;

const RetailPosSytem: React.FC = (props: any) => {
  const {t} = useI18next();
  const {data} = props;

  const categories: BlogCategoryType[] = data.prismic.allCategorys.edges.map(mapCategory) || [];
  const popularPosts: BlogPost[] = getPostsFromPrismicEdges({
    posts: data.popularPosts.allPosts.edges,
    categories
  });
  return (
    <>
      <SEO
        title={t('Retail POS System')}
        description={t(
          'Save time and money and avoid bulky and unreliable retail POS systems by accepting payments from your phone with a mobile payment app. Get MONEI Pay ››'
        )}
      />

      <IndexBackground>
        <Content>
          <Section columnLg>
            <MainTitleContent sx={{maxWidth: {all: '590px', lg: '100%'}}}>
              <SectionHeader underline tagName="h1">
                <Trans>Retail POS system: the best alternative</Trans>
              </SectionHeader>
              <Trans parent="p">
                Tired of dealing with expensive and unreliable POS systems for retail? Break free
                from traditional retail POS systems to <Bold>save time and money</Bold> and improve
                the in-store payment experience.
              </Trans>
              <Trans parent="p">
                Use <Bold>MONEI Pay</Bold> to turn any location into a point of sale with a quick
                and secure mobile payment app. Take card and digital wallet payments from your phone
                or tablet, never run out of devices for staff, and view sales results anytime.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Get MONEI Pay</Trans>
                </SignUpButton>
                <ContactSalesButton />
                <CompatibleWith />
              </SectionActions>
            </MainTitleContent>
            <MoneiVideoSection />
          </Section>
        </Content>
      </IndexBackground>
      <Content sx={{marginTop: {md: '20px'}}}>
        <Section centered sx={{paddingTop: '20px'}}>
          <Flex direction="column" justifyContent="center" alignItems="center">
            <SectionHeader underline style={{marginTop: '10px', textAlign: 'center'}}>
              <Trans>Benefits of accepting retail payments without a POS</Trans>
            </SectionHeader>
            <p style={{textAlign: 'center'}}>
              <Trans>
                Skip the bulky hardware and high costs that come with traditional retail POS systems
                and use a simple, secure, and more affordable mobile payment app to{' '}
                <BlogLink slug="accept-card-payments-on-phone">
                  accept credit card payments from your phone
                </BlogLink>{' '}
                or tablet with digital <MoneiPayQrLink>QR codes</MoneiPayQrLink>.
              </Trans>
            </p>

            <CardsContainer sx={{marginTop: '100px'}}>
              <Card padding="80px 32px 32px 32px">
                <CardImage src={cards} alt="" style={{top: -40, width: 80}} />
                <CardWrapper>
                  <Trans>
                    Accept many{' '}
                    <InternalPageLink slug="payment-methods">payment methods</InternalPageLink> like{' '}
                    <InternalPageLink slug="payment-methods/credit-cards">cards</InternalPageLink>,{' '}
                    <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>,{' '}
                    <InternalPageLink slug="payment-methods/google-pay">
                      Google Pay
                    </InternalPageLink>
                    , and <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink>
                  </Trans>
                </CardWrapper>
              </Card>
              <Card padding="80px 32px 32px 32px">
                <CardImage src={mobile_payment} alt="" style={{top: -45, width: 110}} />
                <CardWrapper>
                  <Trans>
                    <BlogLink slug="benefits-of-mobile-payments">Take mobile payments</BlogLink> and
                    issue <SupportLink slug="/articles/8418026491537">refunds</SupportLink> anywhere
                    in-store or on the go
                  </Trans>
                </CardWrapper>
              </Card>
              <Card padding="80px 32px 32px 32px">
                <CardImage src={cart2} alt="" style={{top: -55, width: 100}} />
                <CardWrapper>
                  <Trans>Avoid customer queues by building payment stations around your shop</Trans>
                </CardWrapper>
              </Card>
              <Card padding="80px 32px 32px 32px">
                <CardImage src={money} alt="" style={{top: -48}} />
                <CardWrapper>
                  <Trans>
                    Save up to 50% on hardware and <PricingLink>transaction fees</PricingLink>
                  </Trans>
                </CardWrapper>
              </Card>
              <Card padding="80px 32px 32px 32px">
                <CardImage src={megaphone} alt="" style={{top: -40, width: 100}} />
                <CardWrapper>
                  <Trans>
                    Boost brand awareness with a{' '}
                    <SupportLink slug="/articles/8797620433937">
                      customized QR payment page
                    </SupportLink>
                  </Trans>
                </CardWrapper>
              </Card>
              <Card padding="80px 32px 32px 32px">
                <CardImage src={person} alt="" style={{top: -60, width: 90}} />
                <CardWrapper>
                  <Trans>Send digital receipts via email, SMS, or WhatsApp</Trans>
                </CardWrapper>
              </Card>
            </CardsContainer>
          </Flex>
        </Section>
        <Flex alignItems="center" direction="column">
          <Trans parent="p">
            👇 <Bold>Download your mobile payment app for retail today</Bold> 👇
          </Trans>
          <Flex>
            <div style={{marginRight: '16px'}}>
              <DownloadOnAppleStore />
            </div>
            <DownloadOnGooglePlay />
          </Flex>
        </Flex>
      </Content>
      <Background style={{marginTop: '50px'}}>
        <Content>
          <Section sx={{paddingTop: {sm: '100px'}, alignItems: 'center'}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
              <SectionHeader style={{fontSize: '2rem'}} underline>
                <Trans>How does MONEI Pay for retail work?</Trans>
              </SectionHeader>
              <Trans parent="p">
                MONEI Pay is a simple yet powerful payment solution for retail stores.
              </Trans>
              <OrderedList>
                <Trans parent="li">
                  <SignUpLink>Sign up</SignUpLink> for MONEI
                </Trans>
                <Trans parent="li">
                  <SupportLink slug="/articles/6167527597457">Download</SupportLink> the mobile
                  payment app to your smartphone or tablet
                </Trans>
                <Trans parent="li">
                  <SupportLink slug="/articles/4417709411729">Add users</SupportLink> to equip your
                  store staff to accept payments anywhere
                </Trans>
                <Trans parent="li">
                  <SupportLink slug="/articles/4417709618193">
                    Create digital QR code payments
                  </SupportLink>{' '}
                  by entering the purchase amount
                </Trans>
                <Trans parent="li">Customer scans the QR code to pay</Trans>
                <Trans parent="li">
                  Customer selects their preferred{' '}
                  <SupportLink slug="/articles/4417709486737">payment method</SupportLink>
                </Trans>
                <Trans parent="li">
                  Customer finishes the transaction with the tap of a button
                </Trans>
              </OrderedList>
              <SignUpButton variant="dark">
                <Trans>Try MONEI Pay</Trans>
              </SignUpButton>
            </div>
            <RotatedScreenshots />
          </Section>
        </Content>
      </Background>

      <Content>
        <Section reverseOnMobile centered sx={{paddingTop: '40px'}}>
          <QrGroup />
          <div>
            <SectionHeader underline tagName="h3">
              <Trans>Start accepting retail payments on any mobile device</Trans>
            </SectionHeader>
            <Trans parent="p">
              Getting started is easy. Open your MONEI account, then download the{' '}
              <AndroidDownloadLink>Android</AndroidDownloadLink> or{' '}
              <IosDownloadLink>iOS</IosDownloadLink> mobile payment app on one or many devices to
              accept mobile payments in your retail store.
            </Trans>
            <StyledDiv sx={{display: {all: 'inline', sm: 'none'}}}>
              <Trans parent="p">
                👈 <Bold>Scan any QR code to test the payment experience.</Bold>
              </Trans>
            </StyledDiv>
            <SignUpButton variant="light">
              <Trans>Open an Account</Trans>
            </SignUpButton>
          </div>
        </Section>
      </Content>
      {popularPosts.length > 0 && (
        <>
          <Content>
            <Section sx={{paddingBottom: '0px'}}>
              <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                <SectionHeader underline sx={{textAlign: 'center'}}>
                  <Trans>Latest news about physical payments and retail</Trans>
                </SectionHeader>
              </div>
            </Section>
          </Content>
          <RelatedBlogPosts posts={popularPosts} />
        </>
      )}
    </>
  );
};

export default RetailPosSytem;

export const query = graphql`
  query ($language: String!, $prismicLang: String) {
    locales: allLocale(
      filter: {ns: {in: ["common", "retail-POS-system"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    prismic: prismic {
      allCategorys(lang: $prismicLang) {
        edges {
          node {
            title
            _meta {
              uid
              lang
            }
            order
          }
        }
      }
    }
    popularPosts: prismic {
      allPosts(
        sortBy: meta_lastPublicationDate_DESC
        tags: "Pagos en persona"
        lang: $prismicLang
        first: 4
      ) {
        edges {
          node {
            title
            image
            summary
            _meta {
              uid
            }
          }
        }
      }
    }
  }
`;
